import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Row, Container } from 'react-bootstrap';
import $ from "jquery";

/**
 * Assets
 */
import './styles/_index.scss';

/**
 * Components
 */
import ReviewLine from '@components/review-line/review-line';
import ReviewRow from '@components/review-row/review-row';
import ContactBox from '@components/contact-box/contact-box';
import InnerBanner from '@components/inner-banner/inner-banner';

const ServiceRating = (props) => {
    const active = props.active;
    const data = useStaticQuery(graphql`
        query {
            reviews: file(relativePath: { eq: "reviews-purple.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1024) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            avatar: file(relativePath: { eq: "avatar.png" }) {
                childImageSharp {
                    fluid(maxWidth: 180) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    let AOS;
    useEffect(() => {
        const AOS = require("aos");
        AOS.init({
            once: true,
            disable: 'mobile'
        });
    }, [])

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }

        var win = $(window);
        win.scroll(function () {
            var sticky = $('.sticky-header'),
                scroll = win.scrollTop();

            if (scroll >= 40) sticky.addClass('fixed');
            else sticky.removeClass('fixed');
        });
    });

    return (
        <>
                <InnerBanner />

                <div className="service-rating">
                <Container>
                    <Row>
                        <Col md="12" lg={8}>
                            <ReviewLine>
                                <h3>97% Customer Service Rating</h3>
                                <small>Based on 665 Reviews</small>
                            </ReviewLine>
                            <ReviewRow />
                            <p>Ari went above and beyond to ensure the chain kept moving. Thank you so much for all your help. And what a lovely surprise to pick up my keys attached to a bottle wine!</p>
                            <h6>02 Aug 2020</h6>
                            <h5 className="pb-5">Anna Ryle (buyer)</h5>

                            <ReviewRow />
                            <p>Mina was a pleasure to deal with, such a charming young man.</p>
                            <h6>02 Aug 2020</h6>
                            <h5 className="pb-5">Luke Harwood (seller)</h5>

                            <ReviewRow />
                            <p>Andy was a reliable contact. Always ready to help when needed. Thank you for selling my property overcoming a number of obstacles.</p>
                            <h6>02 Aug 2020</h6>
                            <h5 className="pb-5">Marie O’Donoghue (seller)</h5>

                            <ReviewRow />
                            <p>Everyone at Anthony Pepe was very attentive and patient throughout the whole process and whilst very easy to talk to they maintained a professional manner. We are very happy with the service provided.</p>
                            <h6>02 Aug 2020</h6>
                            <h5 className="pb-5">Anna Ryle (buyer)</h5>

                            <ReviewRow />
                            <p>Mina was a pleasure to deal with, such a charming young man.</p>
                            <h6>02 Aug 2020</h6>
                            <h5 className="pb-5">Luke Harwood (seller)</h5>

                            <ReviewRow />
                            <p>Andy was a reliable contact. Always ready to help when needed. Thank you for selling my property overcoming a number of obstacles.</p>
                            <h6>02 Aug 2020</h6>
                            <h5 className="pb-5">Marie O’Donoghue (seller)</h5>

                            <ReviewRow />
                            <p>Everyone at Anthony Pepe was very attentive and patient throughout the whole process and whilst very easy to talk to they maintained a professional manner. We are very happy with the service provided.</p>
                            <h6>02 Aug 2020</h6>
                            <h5 className="pb-5">Luke Harwood (seller)</h5>
                        </Col>

                        <Col md="12" lg={4}>
                            <div className="background-grey">
                                <h2>We’re always on hand to help.</h2>

                                <div className="pb-5">
                                    <a className="btn" href={`#`}>Let’s Chat</a>
                                </div>

                                <p>By clicking Let’s Chat, you agree to our <b>Terms &amp; Conditions</b>.</p>
                                <ContactBox name="Stephanie Stavrou" phone="0208 882 6568" link="/" linkText="Email" image={data.avatar.childImageSharp.fluid} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ServiceRating
