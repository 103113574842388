import React from "react"

import Layout from "../components/layout";

import "aos/dist/aos.css";

import ServiceRating from "@components/service-rating/service-rating";

const AboutUs = () => (
  <Layout>

    <ServiceRating />

  </Layout>
)

export default AboutUs
